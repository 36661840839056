function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
    });
  });
}

jQuery(function($) {
/*********************************************************************
  common
*********************************************************************/
slideout_menu();
/*********************************************************************
  index
*********************************************************************/
$("#js-so_menu a").on("click", function() {
$(".-main ul").slideToggle();
$("html").removeClass("slideout-open");
});

/*********************************************************************
  faq
*********************************************************************/

$(".close_box").hide();
$(".open_btn").click(function() {
  $(this).next().slideToggle();
    if ($(this).hasClass("open")) {
      $(this).removeClass("open");
    } else {
      $(this).addClass("open");
    }
});

/*********************************************************************
  reserve
*********************************************************************/

  $('.datetimepicker_js').each(function(){
    $(this).prop('readonly', 'readonly');
  });

  $('#timepicker').timepicker({
    'minTime': '9:00am',
    'maxTime': '7:30pm',
    'timeFormat': 'A h:i',
    'step': 15
  });

  $('#datepicker').datepicker({
      dateFormat: 'yy/mm/dd',
      beforeShowDay: function (date) {
        if(date.getMonth() == 0){
            if(date.getDate() == 1){
              return [false, 'ui-state-disabled'];
            }else if(date.getDate() == 2){
              return [false, 'ui-state-disabled'];
            }else if(date.getDate() == 3){
              return [false, 'ui-state-disabled'];
            }else if(date.getDay() == 2){
              return [false, 'ui-state-disabled'];
            }else{
              return [true, ''];
            }
        }
        else if(date.getMonth() == 4){
          if(date.getDate() == 4){
            return [true, ''];
          } else {
              if (date.getDay() == 2) {
              return [false, 'ui-state-disabled'];
            } else {
                return [true, ''];
            }
          }
          
        }else if(date.getMonth() == 11){
          return [true, ''];
        }else if(date.getMonth() == 1){
          if(date.getDate() == 23){
            return [true, ''];
          }else if(date.getDay() == 2){
            return [false, 'ui-state-disabled'];
          }else{
              return [true, ''];
            }
        }else{
          if (date.getDay() == 2) {
              return [false, 'ui-state-disabled'];
          } else {
              return [true, ''];
          }
        }
      }
    });
});
jQuery(window).on('load',function($){
});
jQuery(window).on('scroll',function($){
});
jQuery(window).on('resize',function($){
});
